import { Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";

import { AppComponent } from "./app.component";
import { InternalGuard } from "./auth/internal.guard";
import { VendorGuard } from "./auth/vendor.guard";
import { LoginComponent } from "./login/login.component";
import { PageNotFoundComponent } from "./modules/shared/page-not-found/page-not-found.component";
import { TabComponent } from "./shared/components/tab/tab.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";

export const routes: Routes = [
    {
        path: "",
        component: LoginComponent,
    },
    {
        path: "unauthorized",
        component: UnauthorizedComponent,
    },
    {
        path: "verification",
        loadChildren: () => import("./modules/verification/verification.module").then(
            (m) => m.VerificationModule
        ),
    },
    {
        path: "dashboard",
        loadChildren: () => import("./modules/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
        ),
        // canLoad: [AutoLoginPartialRoutesGuard],
        canActivate: [MsalGuard, InternalGuard]
    },
    {
        path: "notification",
        loadChildren: () => import("./modules/notification/notification.module").then(
            (m) => m.NotificationModule
        ),
        // canLoad: [AutoLoginPartialRoutesGuard],
    },
    {
        path: "app",
        component: AppComponent,
    },
    {
        path: "vendor",
        loadChildren: () => import("./modules/vendor/vendor.module").then((m) => m.VendorModule),
        // canLoad: [AutoLoginPartialRoutesGuard]
        canActivate: [MsalGuard, VendorGuard]
    },
    {
        path: "internal",
        loadChildren: () => import("./modules/Internal-user/internal-user.module").then(
            (m) => m.InternalUserModule
        ),
        // canLoad: [AutoLoginPartialRoutesGuard],
        canActivate: [MsalGuard, InternalGuard]
    },

    {
        path: "document",
        loadChildren: () => import("./modules/document-templates/document-templates.module")
            .then((m) => m.DocumentTemplatesModule),
        // canLoad: [AutoLoginPartialRoutesGuard],
        canActivate: [MsalGuard, InternalGuard]
    },
    {
        path: "configuration",
        loadChildren: () => import("./modules/configuration/configuration.module").then((m) => m.ConfigurationModule),
        // canLoad: [AutoLoginPartialRoutesGuard],
        canActivate: [MsalGuard, InternalGuard]
    },
    {
        path: "logout",
        component: LoginComponent,
    },
    {
        path: "tab/:accountNumber",
        component: TabComponent,
    },
    {
        path: "discussions",
        loadChildren: () => import("./modules/discussion-board/discussion-board.module")
            .then((m) => m.DiscussionBoardModule),
        // canLoad: [AutoLoginPartialRoutesGuard]
        canActivate: [MsalGuard]
    },
    {
        path: "**",
        pathMatch: "full",
        component: PageNotFoundComponent
    }
];
